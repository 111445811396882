*,
::after,
::before {
    box-sizing: border-box;
}

html {
    font-size: 12px;
}

body {
    font-size: 1rem;
    font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
}

.calendar {
    display: inline-flex;
    margin: 1rem;
}

.month {
    min-width: 20rem;
    width: 20rem;
    max-width: 20rem;
}

.month-header {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.month-header > * {
    line-height: 2;
}

.month-name {
    font-weight: 800;
}

.month-days {
    display: flex;
    flex-direction: column;
}

.month-day {
    box-shadow: 1px 0 0 0 #000, 0 1px 0 0 #000, 1px 1px 0 0 #000, inset 1px 0 0 0 #000, inset 0 1px 0 0 #000;
    display: flex;
    height: 2.25rem;
 }

.month-day__sunday {
    background-color: #ddd;
}

.month-day__work {
    background-color: #f5cbcc;
}

.month-day__off {
    background-color: #fff3cc;
}

.month-day__buffer {
    background-color: #d9ead3;
}

.month-day__today {
    font-weight: bold;
}

.month-day-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.25rem;
    font-size: 0.9rem;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
}

.month-day-number__saturday {
    background-color: #ddd;
}

.month-day-description {
    flex: 1;
    align-items: center;
    display: flex;
}

.month-day-description-text {
    flex: 1;
    padding: 0 0.25rem;
    font-size: 0.9rem;
}

.month-day-description-text > span {
    margin-right: 0.25rem;
}

.month-day-description-text__delivery {
    color: #4c8a30;
}

.month-day-description-text__deadline {
    color: #7da3c4;
}

.month-day-description-status {
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    position: relative;
}

.month-day-description-status > span {
    z-index: 2;
}

.month-day-description-status__delivery {
    background-color: #93c47d;
    border-top: 1px solid #000;
}

.month-day-description-status__vacation {
    background-color: #b9c2c9;
    border-top: 1px solid #000;
}

.month-day-description-status__off {
    background-color: #fff3cc;
    border-top: 1px solid #000;
}

.month-day-description-status__delivery-vacation::before {
    content: "";
    display: inline-block;
    width: 1.125rem;
    height: 2.25rem;
    background-color: #93c47d;
    border-top: 1px solid #000;
    position: absolute;
    left: 0;
}

.month-day-description-status__delivery-vacation::after {
    content: "";
    display: inline-block;
    width: 1.125rem;
    height: 2.25rem;
    background-color: #b9c2c9;
    border-top: 1px solid #000;
    position: absolute;
    right: 0;
}

.month-day-description-status-hanna {
    position: absolute;
    bottom: 0.2rem;
    left: 0.2rem;
    right: 0.2rem;
    top: 0.2rem;
    border-radius: 100%;
    background-color: #7da3c4;
    z-index: 1 !important;
}

.month-day-description-status-competition::before {
    position: absolute;
    top: 0.5rem;
    left: -0.5rem;
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border: 1.125rem solid transparent;
    border-bottom: 0;
    border-right: 1.125rem solid #ff6100;
}

.month-day-description-status-competition::after {
    position: absolute;
    top: 0.5rem;
    left: 0.6rem;
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border: 1.125rem solid transparent;
    border-top: 0;
    border-left: 1.125rem solid #fff;
}

.is-done {
    text-decoration: line-through;
}
